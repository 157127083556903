<template>
  <div 
    v-if="viewList.length"
    ref="sideslipImageRef"
    class="sideslip"
    :class="{ 'hasBottomSpace': !disableBottomSpacing }"
    :style="bgStyle"
  >
    <!-- 轮播图 -->
    <template
      v-if="propData.styleType === 'IMAGE_CAROUSEL_COMPONENT' || propData.styleType === 'IMAGE_CAROUSEL_DYNAMIC' "
    >
      <CarouselImage
        :prop-data="propData"
        :index="index"
        :scene-data="sceneData"
        :cate-links="cateLinks"
        :brand="brand"
      />
    </template>
    <!-- 横滑2.5、横滑3.5、横滑4.5 - 自由滑动 -->
    <template v-if="sliderMap.includes(propData.styleType) || swiperDynamicMap.includes(propData.styleType) || isThreePointFiveDynamicImage">
      <div
        v-if="isThreePointFiveImage && showFloorTitle"
        class="sideslip__title"
        :class="{
          'sideslip__picTitle': floorTitleType === 'image',
        }"
        :style="{
          textAlign: cssRight && floorTitlePosition === 'left' ? 'right' : floorTitlePosition,
          color: floorTitleColor,
        }"
      >
        <span v-if="floorTitleType === 'text'">{{ floorTitle }}</span>
        <BaseImg
          v-if="floorTitleType === 'image'"
          fit="contain"
          style="height: 20px;padding: auto;"
          :img-src="titleImage.src"
          :class="floorTitlePosition === 'center' ? 'sideslip__title_center-img' : ''"
        />
      </div>
      <SideslipImage
        :data="viewList"
        :prop-data="propData"
        :index="index"
        :scene-data="sceneData"
        :cate-links="cateLinks"
        :brand="brand"
        :comp-src="'side-slip-image'"
        @baseImageMounted=" baseImageMounted()"
      />
    </template>
    <!-- 横滑2、3、4、5 - Swiper滑动 -->
    <template v-if="isHorizontalSlide">
      <template v-if="isMarqueeSlide">
        <!-- 无极横滑模式 -->
        <SidelipMarquee
          :view-list="viewList"
          :scene-data="sceneData"
          :prop-data="propData"
          :cate-links="cateLinks"
          :index="index"
          :brand="brand"
          @baseImageMounted=" baseImageMounted()"
        />
      </template>
      <template v-else>
        <!-- tab 模式展示tab -->
        <s-tab
          v-if="isTabMode && tabTextList.length > 1"
          v-model="selectedTabIndex"
          :type="'auto'"
          v-bind="tabTextStyle"
          class="sideslip__tab"
          :customStyle="{
            border: 0,
          }"
          @change="handleTabChange"
        >
          <s-tab-item
            v-for="(item, i) in tabTextList"
            :id="i+1"
            :key="i"
            v-tap="getAnalysisData('2-22-2', { tab: {tabText: item, index: i},'act_nm': 'click_tab', index: i })"
            class="sideslip__tab-item"
          >
            {{ item }}
          </s-tab-item>
        </s-tab>

        <swiper-container
          ref="cccSwiperInstance"
          init="false"
          destroy-on-disconnected="false"
          slides-per-view="1"
          slides-per-group="1"
          space-between="8"
          :lazy-instantiated="true"
          :activated-update="false"
          class="sideslip__swiper"
          @swiperslidechangetransitionstart="handleSwiperStart"
        >
          <swiper-slide 
            v-for="(item, i) in viewList" 
            :key="i"
            class="sideslip__swiper-slide"
          >
            <SideslipImage
              :current-page-index="i"
              :data="item.items"
              :index="index"
              :tab-text="tabTextList[i]"
              :is-slider="false"
              :prop-data="propData"
              :scene-data="sceneData"
              :cate-links="cateLinks"
              :brand="brand"
              :comp-src="'side-slip-image'"
              @baseImageMounted="baseImageMounted()"
            />
          </swiper-slide>
        </swiper-container>

        <!-- 普通模式 展示pagination -->
        <div
          v-if="viewList.length > 1 && !isTabMode"
          class="sideslip__pagination"
        >
          <div
            class="sideslip__pagination-area"
            :style="{ width: `${12 * viewList.length}px` }"
          >
            <i
              class="sideslip__pagination-active"
              :style="{
                transform: `translateX(${100 * swiperIndex}%)`,
              }"
            ></i>
          </div>
        </div>
      </template>
    </template>
    <!-- 外露轮播 -->
    <template v-if="propData.styleType === 'IMAGE_CAROUSEL_EXPOSE'">
      <Sidelip3DCenter
        v-if="carouselEffect === 'center'"
        :index="index"
        :prop-data="propData"
        :scene-data="sceneData"
        :cate-links="cateLinks"
        :brand="brand"
        :context="context"
      />
      <Sidelip3DLeft
        v-if="carouselEffect === 'left'"
        :index="index"
        :prop-data="propData"
        :scene-data="sceneData"
        :cate-links="cateLinks"
        :brand="brand"
        :context="context"
      />
    </template>
  </div>
</template>

<script>
import { STabItem } from '@shein-aidc/sui-tab-item/mobile'
import { STab } from '@shein-aidc/sui-tab/mobile'
import { tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import SideslipImage from './SideslipImage.vue'
import CarouselImage from '../CarouselImage.vue'
import Sidelip3DCenter from './Sidelip3DCenter.vue'
import Sidelip3DLeft from './Sidelip3DLeft.vue'
import SidelipMarquee from './SideslipMarquee.vue'
import mixin from '../mixins/mixin'
import BaseImg from '../../base/BaseImg'
import { register } from 'swiper/element'
typeof window !== 'undefined' && register()

export default defineComponent({
  name: 'SideSlipImage',
  directives: {
    tap,
  },
  components: {
    SideslipImage,
    CarouselImage,
    Sidelip3DCenter,
    Sidelip3DLeft,
    SidelipMarquee,
    STab,
    STabItem,
    BaseImg,
  },
  mixins: [mixin],
  emits: ['baseImageMounted'],
  props: commonProps,
  provide() {
    return {
      isFirstPage: this.propData?.isFirstPage,
    }
  },
  data() {
    const { props: { metaData: { 
      tabSelectedColor = '#222222', 
      tabSelectColor = '#767676', 
      tabBgColor = 'white', 
      disableBottomSpacing = true, 
      carouselEffect = '', 
      showConvertScreenTab = false, 
      isPromiseSlide = false,
      // 横滑 3.5 图新增标题配置
      showFloorTitle,
      floorTitleType = 'text', // 标题类型 text || image
      floorTitle = '',
      floorTitlePosition = 'left', // 标题位置 left || center
      floorTitleColor = '',
      titleImage = {},
      sliderBgColor,
      bgFillType,
      backgroundColor,
      backgroundImg,
    } } } = this.propData || {}
    return {
      showFloorTitle,
      floorTitleType,
      floorTitle,
      floorTitlePosition,
      floorTitleColor,
      titleImage,

      isMarqueeMode: isPromiseSlide, // 无极横滑模式(跑马灯)
      isTabMode: showConvertScreenTab, // Tab模式
      disableBottomSpacing,
      carouselEffect,
      tabTextStyle: {
        selectedColor: tabSelectedColor,
        unSelectedColor: tabSelectColor, 
        bgColor: tabBgColor,
      },
      // 初始Swiper配置
      sliderMap: [
        'ITEM_IMAGE_CAROUSEL_TWO_POINT_FIVE',
        'ITEM_IMAGE_CAROUSEL_THREE_POINT_FIVE',
        'ITEM_IMAGE_CAROUSEL_FOUR_POINT_FIVE',
      ],
      swiperMap: [
        'ITEM_IMAGE_TWO_COLS_CAROUSEL',
        'ITEM_IMAGE_THREE_COLS_CAROUSEL',
        'ITEM_IMAGE_FOUR_COLS_CAROUSEL',
        'ITEM_IMAGE_FIVE_COLS_CAROUSEL',
      ],
      // 动态组件 - Swiper滑动（兼容动态组件非卡片化时使用静态组件样式）
      swiperDynamicMap: [
        'MULTI_ITEMS_CAROUSEL_TABS_DYNAMIC', // 横滑4图 - 横滑多tabs
        'MULTI_ITEMS_CAROUSEL_IND_DYNAMIC', // 横滑4图 - 整屏滑动
        'MULTI_ITEMS_CAROUSEL_CON_DYNAMIC', // 横滑4图 - 无极滑动
        // 'MULTI_ITEMS_CAROUSEL_THREE_POINT_FIVE_DYNAMIC' // 横滑3.5图
      ],
      // swiperIndex从0开始
      swiperIndex: 0,
      // selectedTabIndex从1开始
      selectedTabIndex: 1,
      sliderBgColor,
      bgFillType,
      backgroundColor,
      backgroundImg,
    }
  },
  computed: {
    cssRight() {
      return this.context?.GB_cssRight
    },
    viewList() {
      return this.propData.props.items
    },
    tabTextList(){
      return this.isTabMode ? this.propData.props.items.map(item => item.tabText) : []
    },
    isThreePointFiveImage() {
      // return this.propData?.styleType === 'ITEM_IMAGE_CAROUSEL_THREE_POINT_FIVE'
      return this.sliderMap.includes(this.propData?.styleType)
    },
    isThreePointFiveDynamicImage() {
      return this.propData?.styleType === 'MULTI_ITEMS_CAROUSEL_THREE_POINT_FIVE_DYNAMIC'
    },
    // 是否为横滑多图
    isHorizontalSlide() {
      return this.swiperMap.includes(this.propData.styleType) || this.swiperDynamicMap.includes(this.propData.styleType)
    },
    // 是否为无极横滑
    isMarqueeSlide() {
      return this.isMarqueeMode || this.propData.styleType === 'MULTI_ITEMS_CAROUSEL_CON_DYNAMIC'
    },
    idNeedSwiperInit() {
      return this.isHorizontalSlide && !this.isMarqueeSlide
    },
    bgStyle() {
      // 横滑4.5图 走皮肤需求新增的字段
      if(this.propData?.styleType === 'ITEM_IMAGE_CAROUSEL_THREE_POINT_FIVE'){
        return this.bgFillType == 1 ? { backgroundColor: this.backgroundColor } : { backgroundImage: `url(${this.backgroundImg?.src})`, backgroundSize: '100% 100%' }
      }
      return {
        backgroundColor: this.sliderBgColor || 'transparent'
      }
    }
  },
  mounted() {
    if(this.idNeedSwiperInit){
      const swiperEl = this.$refs.cccSwiperInstance
      swiperEl.initialize()
    }
  },
  activated(){
    if(this.selectedTabIndex){
      this.handleTabChange({ val: this.selectedTabIndex })
    }
  },
  methods: {
    baseImageMounted() {
      this.$emit('baseImageMounted')
    },
    handleSwiperStart() {
      const { GB_cssRight } = gbCommonInfo
      let activeIndex = this.$refs.cccSwiperInstance?.swiper.activeIndex ?? 0

      this.selectedTabIndex = activeIndex + 1
      this.swiperIndex = GB_cssRight ? -activeIndex : activeIndex
    },
    handleTabChange({ val }){
      this.$refs?.cccSwiperInstance?.swiper?.slideTo(val - 1)
    },
  },
})
</script>

<style lang="less" scoped>
@vw: 375/100vw;

.sideslip {
  &__title {
    margin: 4px 0 2px;
    padding: 0 12/@vw;
    width: 100%;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    :deep(.base-img__inner) {
        width:auto;
      }
    &_center-img{
      :deep(.base-img__inner) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &__picTitle {
    margin-top: 2px;
  }
  &.hasBottomSpace {
    margin-bottom: 8 / @vw;
  }
  &__pagination {
    padding: 6px 0;
  }

  &__pagination-area {
    margin: 0 auto;
    width: 24px;
    position: relative;
    background-color: #e5e5e5;
    overflow: hidden;
  }
  &__pagination-active {
    display: block;
    height: 3px;
    width: 12px;
    background-color: #222;
    transition: transform 0.2s;
  }
  .sideslip-placeholder {
    width: 100%;
    height: 12px;
  }
  :deep(.base-img){
    margin-bottom: 0px;
  }
  
  .sideslip__swiper {
    :deep(.swiper-wrapper) {
      will-change: transform; // 兼容低端安卓机白边问题
    }
  }

  &__swiper{
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  &__swiper-slide{
    --slides-per-view: 1;
    width: calc(100% / var(--slides-per-view));
    flex-shrink: 0;
  }
}

.store-page-home--margin .sideslip {
  margin: 0 12/37.5rem;
  &.hasBottomSpace {
    margin-bottom: 10 / @vw;
  }
}
</style>
