<template>
  <swiper-container
    ref="swiper"
    class="sideslip-marquee_swiper"
    destroy-on-disconnected="false"
    init="false"
    :space-between="intervalSpacing ? 8 : 0"
    :slides-per-view="4.32"
    :slides-per-group="slides * 4"
    :free-mode="true"
    :free-mode-sticky="false"
    :free-mode-momentum-bounce="false"
    :free-mode-momentum="false"
    :free-mode-momentum-ratio="0.3"
    :resistance-ratio="0.4"
    :loop="false"
    :autoplay-delay="5"
    :autoplay-stop-on-last-slide="true"
    :autoplay-disable-on-interaction="true"
    :speed="(slides -1 ) * 4 * 2000"
    @swiperslidermove="handleSliderMove"
  >
    <swiper-slide
      v-for="(cols, _i) in colsItemList"
      :key="_i"
      class="sideslip-marquee_swiper-slide"
      :class="{
        'slide-intervalSpacing': intervalSpacing,
      }"
    >
      <div
        v-for="(item, i) in cols"
        :key="i"
        @click="clickItem(item, i)"
      >
        <BaseImg
          v-if="item.image"
          v-tap="
            getAnalysisData('2-22-2', {
              item,
              index: i,
              act_nm: isAutoPlayExpose ? 'auto_move' : 'auto_stop',
            })
          "
          v-expose="
            getAnalysisData('2-22-1', {
              item,
              index: i,
              act_nm: isAutoPlayExpose ? 'auto_move' : 'auto_stop',
            })
          "
          :brand="brand"
          :placeholder="{
            width: item.image.width,
            height: item.image.height,
          }"
          :ratio="item.image.ratio"
          :img-src="item.image.src"
          :img-design-width="160"
          :first-screen="isAutoSlide ? true : propData?.isFirstPage && _i < 5"
          :ada="item.ada"
          :class="{ 'fsp-element': _i < 1 }"
          @baseImageMounted="baseImageMounted()"
        />
        <div
          v-if="item.smallTitle"
          :class="{
            ['sideslip-marquee__title']: true,
          }"
        >
          {{ item.smallTitle }}
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
</template>

<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
// import { cccSwiper, cccSwiperSlide } from '../../base/swiper/index'
import BaseImg from '../../base/BaseImg'
import mixin from '../mixins/mixin'
import { register } from 'swiper/element'
import exposeService from 'public/src/services/expose/index.js'
import { Autoplay, FreeMode } from 'swiper/modules'
// swiper 实例方法
import { swiperStartAutoPlay, swiperStopAutoPlay } from 'public/src/pages/components/ccc/common/swiper.js'
typeof window !== 'undefined' && register()
const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default defineComponent({
  name: 'SideslipMarquee',
  directives: {
    expose,
    tap,
  },
  components: {
    BaseImg,
  },
  mixins: [mixin],
  emits: ['baseImageMounted'],
  props: {
    sceneData: {
      type: Object,
      default: () => ({}),
    },
    propData: {
      type: Object,
      default: () => ({}),
    },
    viewList: {
      type: Array,
      default: () => [],
    },
    brand: {
      type: String,
      default: 'shein',
    },
    // 组件的位置
    index: {
      type: Number,
      default: 0,
    },
    cateLinks: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const { metaData } = this.propData.props
    const { intervalSpacing = false, isAutoSlide = false, rows, slides } = metaData

    return {
      isHomePage: this.sceneData.pageFrom === 'homePage',
      itemsPerRow: 4, // 一行有4个item
      intervalSpacing,
      isAutoSlide, // 开启自动滑动开关
      isFirstAutoPlay: true, // 只自动滑动一次（用户操作后停止）
      isAutoPlayExpose: false, // 曝光标志是否由自动滑动触发
      rows, // 一屏有几行
      slides, // 总共有几屏
    }
  },
  computed: {
    colsItemList() {
      // 重新调整viewList的数据结构，方便渲染
      const _colsItemList = [] // rowsItemList[0] 代表第一列里面的数据
      this.viewList &&
        this.viewList.forEach((slides, slidesIndex) => {
          const slidesRows = Array.from({ length: this.itemsPerRow }, () => [])
          slides?.items?.forEach((item, loc) => {
            const colsIndex = loc % this.itemsPerRow
            // 计算item的位置,slidesIndex第几屏、loc第几个坑位。埋点上报
            const itemLoc = `${slidesIndex + 1}_${loc + 1}`
            item['item_loc'] = itemLoc

            slidesRows[colsIndex].push(item)
          })
          _colsItemList.push(...slidesRows)
        })
      return _colsItemList
    },
  },

  async mounted() {
    const swiperEl = this.$refs.swiper
    const swiperParams = {
      on: {
        init: () => {
          this.$refs?.swiper?.swiper?.off('resize')
        },
      },
      modules: [Autoplay, FreeMode],
      injectStylesUrls: [
        // PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/pagination-element.min.css',
        PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/free-mode-element.min.css',
      ],
      injectStyles: [
        `
          .swiper-container {
            overflow: hidden;
          }
          .swiper-free-mode>.swiper-wrapper{
            transition-timing-function : linear;
          }
        `,
      ],
    }
    Object.assign(swiperEl, swiperParams)
    swiperEl.initialize()
    swiperEl.swiper.autoplay.stop()
    this.isAutoSlide && this.autoplay()
  },
  beforeUnmount() {
    this.clearAutoPlay()
  },
  methods: {
    handleSliderMove() {
      this.stopAutoPlay()
    },
    baseImageMounted() {
      this.$emit('baseImageMounted')
    },
    getRealSwiperInstance() {
      return this.$refs.swiper.swiper
    },
    clickItem(item, i) {
      this.stopAutoPlay()
      // this.clearAutoPlay()
      // from mixin
      const url = this.cccLink.getFullLink({
        item,
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: i,
      })

      this.jumpLink({ url, hrefType: item.hrefType })
    },
    pauseAutoPlay() {
      // 自动播放是transition-duration控制的
      // 所以暂停播放只能先获取此刻的偏移量，再通过设置translate来实现
      this.curTranslate = this.$refs.swiper.swiper?.getTranslate()
      this.finalTranslate = this.$refs.swiper.swiper?.translate
      this.$refs.swiper.swiper?.setTranslate(this.curTranslate)
      swiperStopAutoPlay(this.$refs.swiper.swiper)
    },
    resumeAutoPlay(){
      const remainTranslate = Math.abs(this.finalTranslate) - Math.abs(this.curTranslate)
      const remainTime = remainTranslate / Math.abs(this.finalTranslate) * this.speed
      remainTime && this.$refs.swiper.swiper?.slideNext(remainTime)
      swiperStartAutoPlay(this.$refs.swiper.swiper)
    },
    autoplay() {
      const topHide = this.isHomePage ? 88 : 44
      const bottomHide = this.isHomePage ? 47 : 0
      this.obInstance = new exposeService({
        observeHide: true,
        exposeRatio: 0.99,
        mask: [topHide, '0', bottomHide, '0'],
      })
      this.obInstance.observe(
        {
          elements: this.$refs.swiper,
          once: false,
        },
        ({ exposeDoms }) => {
          if (!this.$refs.swiper) return
          if (exposeDoms.length) {
            // 第一次进入视口时候，等待2s后开始自动播放
            if (this.isFirstAutoPlay) {
              clearTimeout(this.timer)
              this.timer = setTimeout(() => {
                // this.$refs.swiper.swiper.autoplay.start()
                swiperStartAutoPlay(this.$refs.swiper.swiper)
                this.isFirstAutoPlay = false
                this.isAutoPlayExpose = true
              }, 2000)
            } else {
              // 第一次开始播放后，再次进入视口时候，直接开始自动播放
              this.resumeAutoPlay()
              // this.$refs.swiper.swiper.autoplay.start()
            }
          } else {
            clearTimeout(this.timer)
            // this.$refs.swiper.swiper.autoplay.stop()
            this.pauseAutoPlay()
          }
        },
      )
    },
    // 清理监听器
    clearAutoPlay() {
      clearTimeout(this.timer)
      // swiper视口监听销毁
      this.obInstance?.destroy?.()
      this.obInstance = null
    },

    // 彻底停止自动滑动
    stopAutoPlay() {
      // 设置超出边缘回弹速度
      const swiper = this.getRealSwiperInstance()
      if (swiper && swiper.params) {
        swiper.params.speed = 9999999999999
      }
      this.isFirstAutoPlay = false
      if(this.obInstance){
        // 用户操作后就停止自动滑动
        swiperStopAutoPlay(this.$refs.swiper.swiper)
        this.isAutoPlayExpose = false
        this.clearAutoPlay()
      }
    },
  },
})
</script>

<style lang="less" scoped>
.sideslip-marquee {
  &__title {
    font-family: Helvetica, Arial, sans-serif;
    font-style: normal;
    text-align: center;
    height: 27px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: #222222;
    margin: 4px;
    word-wrap: break-word;
    .line-camp();
  }
  &_swiper {
    display: flex;
    flex-wrap: nowrap;
  }

  &_swiper-slide {
    flex-shrink: 0;
    --slides-per-view: 4.32;
    width: calc(100% / var(--slides-per-view));
    &.slide-intervalSpacing {
      width: calc((100% - 8px * (var(--slides-per-view) - 1)) / var(--slides-per-view));
      margin-right: 8px;
    }
  }
}
</style>
